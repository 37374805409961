import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN,USER_INFO } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	userInfo: JSON.parse(localStorage.getItem(USER_INFO)!=undefined?localStorage.getItem(USER_INFO):"{}"),
}

export const signIn = createAsyncThunk('auth/signIn',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signInEmailRequest(email, password)
		if (response.user) {
			console.log("response.user==0088:",response.user)
			const token = response.user.refreshToken;
			const userInfo = FirebaseService.getAdmin(response.user.uid);
			if(userInfo!=null){
				if(userInfo.verify==true){
					localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
					localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
					setAdmin(userInfo)
					return token;
				}else{
					message.success({ content: 'This account need to verify by super admin', duration: 5 });
					return rejectWithValue(response.message?.replace('Firebase: ', ''));
				}
			}else{
				FirebaseService.registerDB(response.user.uid, response.user.email)
				return rejectWithValue(response.message?.replace('Firebase: ', ''));
			}
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/signUp',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signUpEmailRequest(email, password)
		if (response.user) {
			const token = response.user.refreshToken;
			localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
			return token;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/signOut',async () => {
    const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
    return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInGoogleRequest()
	if (response.user) {
		console.log("response.user==0088:",response.user)
		const userInfo = await FirebaseService.getAdmin(response.user.uid);
		console.log("user info:",userInfo)
		const token = response.user.refreshToken;
		if(userInfo!=undefined){
			if(userInfo.verify==true){
				localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
				localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
				setAdmin(userInfo)
				authenticated(response.user.uid)
				return token;
			}else{
				message.info({ content: 'This account need to verify by super admin', duration: 5 });
				return rejectWithValue(response.message?.replace('Firebase: ', ''));
			}
		}
		message.error({ content: "This account doesn't be registered as admin", duration: 5 });
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInFacebookRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})

export const updateAdmin = createAsyncThunk('auth/updateAdmin', async (data, { rejectWithValue }) => {
    const response = await FirebaseService.updateAdmin(data)
	try{
		if(response==undefined){
			setAdmin((data))
		  }else if(response.message) {
			showAuthMessage(response.message);
		  }
		  return data;
		}catch(e){
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		},
		setAdmin: (state,action) =>{
			state.userInfo = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	setAdmin,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer