import { initializeApp } from "firebase/app"
import { getFirestore, FieldValue } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import {
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider
} from 'firebase/auth'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import firebaseConfig from 'configs/FirebaseConfig';

const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase App Check
const appCheck = initializeAppCheck(firebaseApp, {
	provider: new ReCaptchaV3Provider('6Ld_EfspAAAAADhGn-Vu-bGVj6ez-j0oec0jgz1G'),
	isTokenAutoRefreshEnabled: true // Optional: Enable token auto-refresh
});

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp)
const currentUser = auth.currentUser
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export {
	db,
	auth,
	storage,
	currentUser,
	googleAuthProvider,
	GoogleAuthProvider,
	facebookAuthProvider,
	FacebookAuthProvider,
	signInWithEmailAndPassword,
	signOut,
	signInWithPopup,
	createUserWithEmailAndPassword
};