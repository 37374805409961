import {
  auth,
  db,
  signInWithEmailAndPassword,
  signOut,
  googleAuthProvider,
  facebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword
} from 'auth/FirebaseAuth';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { message } from 'antd';
import { USER_INFO } from 'constants/AuthConstant';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);
}

FirebaseService.signOutRequest = async () =>
  await signOut(auth).then(user => user).catch(err => err);

FirebaseService.signInGoogleRequest = async () =>
  await signInWithPopup(auth, googleAuthProvider).then(user => user).catch(err => err);

FirebaseService.signInFacebookRequest = async () =>
  await signInWithPopup(auth, facebookAuthProvider).then(user => user).catch(err => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);

FirebaseService.registerDB = async (uid, email) =>
  await db.collection('admin').doc(uid).set({
    'uid': uid,
    'email': email
  }).then(ref => ref).catch(err => err)

FirebaseService.getAdmin = async (uid) => {
  try {
    const docRef = doc(db, "admin", uid);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (e) {
    console.log("e. get admin:",e)
    return null;
  }
}

FirebaseService.updateAdmin = async (userInfo) =>
  {
    console.log("update data userinfo:",userInfo)
    const documentRef = doc(db, 'admin', userInfo.uid);
    const data = userInfo

    console.log("update data userinfo:",data)
    await updateDoc(documentRef, data)
    localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
  }
FirebaseService.resetPassword = async (data) => {
  //   const credential = EmailAuthProvider.credential(
  //     auth.currentUser.email,
  //     data.oldPassword
  //  );
  //  await reauthenticateWithCredential(auth.currentUser, credential);
  //  await updatePassword(auth.currentUser, data.newPassword);
  var user = auth.currentUser;
  user.updatePassword(data.newPassword).then(function () {
    // Update successful.
    message.success({ content: 'Password changed successfully', duration: 5 });
  }).catch(function (error) {
    console.log("reset password error:", error);
    message.info({ content: 'Please relogin to change password', duration: 5 });
    // An error happened.
  });
}

export default FirebaseService