// const FirebaseConfig = {
//   apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
//   authDomain: 'emilus.firebaseapp.com',
//   databaseURL: 'https://emilus.firebaseio.com',
//   projectId: 'emilus',
//   storageBucket: 'emilus.appspot.com',
//   messagingSenderId: '807555350717',
//   appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
//   measurementId: 'G-8KE7HJB252'
// };

const FirebaseConfig = {
  apiKey: "AIzaSyBs2MqtINMU21B3NkpiMXYQE4WJ_AiAtbM",
  databaseURL: "https://flirty-6efc3-default-rtdb.firebaseio.com",
  authDomain: "flirty-6efc3.firebaseapp.com",
  projectId: "flirty-6efc3",
  storageBucket: "flirty-6efc3.appspot.com",
  messagingSenderId: "569980038181",
  appId: "1:569980038181:web:aa1d54049a69b0061723cc",
  measurementId: "G-37Y5L7V79K"
};

export default FirebaseConfig

