import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FirebaseService from 'services/FirebaseService';
import ReportsService from 'services/ReportsService';
import { message } from 'antd';
import { AUTH_TOKEN } from 'constants/AuthConstant'
import StoreService from 'services/StoreService';

const initialState = {
    users: [],
    banUsers: [],
    images: [],
    videos: [],
    feedbacks: []
}

export const fetchUsers = createAsyncThunk('report/fetchUsers',async (data, { rejectWithValue }) => {
	try {
		const response = await ReportsService.getUsers()
        return response
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const fetchBanUsers = createAsyncThunk('report/fetchBanUsers',async (data, { rejectWithValue }) => {
	try {
		const response = await ReportsService.getBanUsers()
        return response
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const fetchImages = createAsyncThunk('report/fetchImages',async (data, { rejectWithValue }) => {
	try {
		const response = await StoreService.getImages()
        return response
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const fetchFeedbacks = createAsyncThunk('report/fetchFeedbacks',async (data, { rejectWithValue }) => {
	try {
		const response = await StoreService.getFeedbacks()
        return response
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const fetchVideos = createAsyncThunk('report/fetchVideos',async (data, { rejectWithValue }) => {
	try {
		const response = await StoreService.getVideos()
        return response
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})
export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers:{
        fetchUsers:(state,action)=>{
            state.loading = false
            state.users = action.payload
        },
        fetchBanUsers:(state,action)=>{
            state.loading = false
            state.banUsers = action.payload
        },
        fetchImages:(state,action)=>{
            state.loading = false
            state.images = action.payload
        },
        fetchFeedbacks:(state,action)=>{
            state.loading = false
            state.feedbacks = action.payload
        },
        fetchVideos:(state,action)=>{
            state.loading = false
            state.videos = action.payload
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchUsers.pending, (state) => {
            state.loading = true
        })
        .addCase(fetchUsers.fulfilled, (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.users = action.payload
        })
        .addCase(fetchUsers.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })

        .addCase(fetchBanUsers.pending, (state) => {
            state.loading = true
        })
        .addCase(fetchBanUsers.fulfilled, (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.banUsers = action.payload
        })
        .addCase(fetchBanUsers.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })

        .addCase(fetchImages.pending, (state) => {
            state.loading = true
        })
        .addCase(fetchImages.fulfilled, (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.images = action.payload
        })
        .addCase(fetchImages.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })

        .addCase(fetchFeedbacks.pending, (state) => {
            state.loading = true
        })
        .addCase(fetchFeedbacks.fulfilled, (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.feedbacks = action.payload
        })
        .addCase(fetchFeedbacks.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.feedbacks = []
        })

        .addCase(fetchVideos.pending, (state) => {
            state.loading = true
        })
        .addCase(fetchVideos.fulfilled, (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.videos = action.payload
        })
        .addCase(fetchVideos.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
    }
})

export const { 
} = reportSlice.actions

export default reportSlice.reducer