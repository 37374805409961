import { db, storage } from 'auth/FirebaseAuth';
import { doc, getDoc, getDocs, updateDoc, collection, query, where, deleteDoc } from "firebase/firestore";
import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { FirestoreConstants, TypeMessage, VideoConfig } from 'configs/AppConfig';
import axios from 'axios';
import { orderBy } from 'lodash';

const ReportsService = {}

ReportsService.getUsers = async (filter) => {

    var arr = [];
    console.log("running the get reports API")
    const dmsRef = collection(db, "users");
    // const docRef = query(collection(db, "users"), where("stage", "!=", 'ban'), orderBy("reportCount")); //for example id = "71dc954d-d2a4-4892-8257-98696fe776cd"
    const docRef = query(dmsRef, where("stage", "!=", 'ban')); //for example id = "71dc954d-d2a4-4892-8257-98696fe776cd"

    const docSnap = await getDocs(docRef);

    docSnap.forEach((doc) => {
        arr.push(doc.data())
    });

    arr.sort((a, b) => (b.reportCount - a.reportCount))
    return arr;
}

ReportsService.getBanUsers = async (filter) => {
    var arr = [];
    console.log("running the get reports API")

    const dmsRef = collection(db, "users");
    const docRef = query(dmsRef, where("stage", "==", 'ban')); //for example id = "71dc954d-d2a4-4892-8257-98696fe776cd"

    const docSnap = await getDocs(docRef);

    docSnap.forEach((doc) => {
        arr.push(doc.data())
    });

    return arr;
}

ReportsService.addUser = async (ele) => {
    var arr = [];
    console.log("running the delete reports API")
    var uid = "a" + (new Date().getTime() * 1000)
    console.log("uid generated in add function:", uid);
    console.log("ele generated in add function:", ele);
    const dmsRef = collection(db, "users");

    const docRef = query(dmsRef, where('phoneNumber', "==", ele.phoneNumber)); //for example id = "71dc954d-d2a4-4892-8257-98696fe776cd"

    const docSnap = await getDocs(docRef);

    console.log("No such document!", docSnap.docs);
    docSnap.forEach((doc) => {
        arr.push(doc.data())

    });

    if (arr.length == 0) {
        ele.uid = uid
        await db.collection('users').doc(uid).set(ele)
            .catch((error) => {
                console.error("Error deleting document: ", error);
                return false;
            });
        return true;
    } else {
        return false;
    }

}

ReportsService.updateUser = async (ele) => {
    var arr = true;
    console.log("ele generated in add function:", ele);
    const documentRef = doc(db, 'users', ele.uid);
    const data = ele
    await updateDoc(documentRef, data)
    console.log("update user document:")
    return arr;
}

ReportsService.getUser = async (ele) => {
    try {
        const docRef = doc(db, "users", ele);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
    } catch (e) {
        console.log("e. get admin:", e)
        return null;
    }
}

ReportsService.exchangeEmail = async (ele) =>{
    try {

        const docRef = doc(db, "users", ele.uid);
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data())
        console.log(docSnap.data()['newEmail'])
        if(docSnap.data()['newEmail'] == ele.newEmail){
            await updateDoc(docRef, {'email':ele.newEmail,'newEmail':''})
            return "changed";
        }else{
            return "Email doesn't be matched";
        }

    } catch (e) {
        console.log("e. get admin:", e.toString())
        return null;
    }
}

ReportsService.getUserUploaded = async (ele) => {
    var arr = [];
    console.log("running the get reports API", ele.toString())

    const dmsRef = collection(db, "upload");
    const docRef = query(dmsRef, where("uid", "==", ele)); //for example id = "71dc954d-d2a4-4892-8257-98696fe776cd"

    const docSnap = await getDocs(docRef);

    console.log("No such document!", docSnap.docs);
    docSnap.forEach((doc) => {
        arr.push(doc.data())

    });
    return arr;
}

ReportsService.deleteUsers = async (appUser) => {
    var arr = true;
    console.log("running the delete reports API", appUser)
    try {
        for (const item of appUser.friends ?? []) {
            var groupChatId = "";
            console.log("appUser.uid==00:", appUser.uid);
            console.log("appUser.item==00:", item);
            if (appUser.uid.localeCompare(item) > 0) {
                groupChatId = appUser.uid + '-' + item;
            } else {
                groupChatId = item + '-' + appUser.uid;
            }

            const chatDocs = doc(db, FirestoreConstants.pathMessageCollection, groupChatId, groupChatId);

            var snapshot = query(chatDocs);

            getDocs(snapshot)
                .then(querySnapshot => {
                    querySnapshot.forEach((doc) => {
                        deleteDoc(doc.ref)
                            .then(() => console.log(`Document ${doc.id} successfully deleted`))
                            .catch(error => console.error("Error removing document: ", error));
                    });
                })
                .catch(error => {
                    console.log("Error getting documents: ", error);
                });

            const docRef = doc(db, FirestoreConstants.userCollection, item, FirestoreConstants.flirtyCollection, appUser.uid);

            deleteDoc(docRef)
                .then(() => {
                    console.log('Document successfully deleted');
                })
                .catch((error) => {
                    console.error('Error removing document: ', error);
                });
            console.log("appUser removed message and flirty:", item);
        }
    } catch (error) {
        console.error('Error deleting images: ', error);
    }
    console.log("appUser start to remove video:");
    var response = await axios.post('https://ws.api.video/auth/api-key',
        { "apiKey": VideoConfig.apiKey });
    var removeToken = response.data.access_token;

    var usersDoc = collection(db,FirestoreConstants.uploadCollection);
    var usersQuery = query(usersDoc,where('uid', "==", appUser.uid))
    getDocs(usersQuery)
        .then(querySnapshot=>{
            querySnapshot.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                console.log("appUser start to remove ds:", doc);
                var shareOne = JSON.parse(JSON.stringify(doc.data()));
                if (shareOne.type == TypeMessage.image) {
                    const fileRef = ref(storage, shareOne.imageUrl);
                    try {
                        await deleteObject(fileRef).then((value) => {
                            console.log('deleted Successfully');
                        });
                    } catch (e) {
                        console.log("delete image error:", e.toString())
                    }

                } else if (shareOne.type == TypeMessage.video) {
                    var videoUrlParams = shareOne.videoUrl.split("/");
                    try {
                        var response = await axios.delete(
                            'https://ws.api.video/videos/' + videoUrlParams[4],
                            {
                                headers: {
                                    'Authorization':
                                        'Bearer ' + removeToken
                                }
                            },
                        );
                        console.log(["removed result from api.video:", response.statusCode]);
                    } catch (e) {
                        console.log(["video remove error:-----:", e.toString()]);
                    }
                }
              });
        })

    console.log("appUser removed video:");
    var flirtyDoc = collection(db,FirestoreConstants.userCollection,appUser.uid,FirestoreConstants.flirtyCollection)
    var snapshot = query(flirtyDoc)
    getDocs(snapshot)
                .then(querySnapshot => {
                    querySnapshot.forEach((doc) => {
                        deleteDoc(doc.ref)
                            .then(() => console.log(`Document ${doc.id} successfully deleted`))
                            .catch(error => console.error("Error removing document: ", error));
                    });
                })
                .catch(error => {
                    console.log("Error getting documents: ", error);
                });

    const banDoc = doc(db, FirestoreConstants.userCollection,appUser.uid)

    const newData = {
        stage: 'ban',
      };

    updateDoc(banDoc, newData)
      .then(() => {
        console.log('Document successfully updated');
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });

    return true;

}

export default ReportsService