import { db, storage } from 'auth/FirebaseAuth';
import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import axios from 'axios';
import {
    getFirestore, collection, onSnapshot, addDoc, doc, FieldValue,
    query, where, getDocs, getDoc, updateDoc, deleteDoc, orderBy
} from 'firebase/firestore'
import { VideoConfig } from 'configs/AppConfig'

const StoreService = {}

StoreService.getImages = async (filter) => {
    var arr = [];
    console.log("running the get reports API")
    const dmsRef = collection(db, "upload");
    const docRef = query(dmsRef, orderBy("reportCount",'desc'), orderBy("id",'asc'));
    const docSnap = await getDocs(docRef);

    docSnap.forEach((doc) => {
        arr.push(doc.data())
    });

    return arr;
}

StoreService.getFeedbacks = async (filter) => {
    var arr = [];
    console.log("running the get reports API")
    const dmsRef = collection(db, "feedback");
    const docRef = query(dmsRef, orderBy("updatedAt",'desc'));
    const docSnap = await getDocs(docRef);

    docSnap.forEach((doc) => {
        arr.push(doc.data())
    });

    return arr;
}

StoreService.updateFeedback = async (ele) => {
    var arr = true;
    console.log("running the delete reports API")
    console.log("ele generated in add function:", ele);

    const documentRef = doc(db, 'feedback', ele.feedId);
    const data = ele
    await updateDoc(documentRef, data)
    return arr;
}

StoreService.updateImage = async (ele) => {
    var arr = true;
    console.log("running the delete reports API")
    console.log("ele generated in add function:", ele);

    if (ele.savedList != undefined)
        ele.savedList.forEach(async (elm) => {
            console.log("elm 999000:", elm);
            if (elm.includes('message:')) {
                var midList = (elm.split(":")[1]).split("-")
                var mid = midList[0] + "-" + midList[1]
                var sid = midList[2]
                console.log("midList==00:", midList, mid, sid);
                const documentRef = doc(db, 'messages', mid, mid, sid);
                const data = {
                    status: ele.status
                }
                await updateDoc(documentRef, data)
            }
            if (elm.includes('user:')) {
                var midList = elm.split("-")
                var uid = midList[0].split(":")[1]
                if (midList[1] == "profile") {
                    const docRef = doc(db, "users", uid);
                    const docSnap = await getDoc(docRef);
                    arr = docSnap.data()
                    if (arr.finalProfile.id == ele.id) {
                        try {
                            const documentRef = doc(db, 'users', uid);
                            const data = {
                                "finalProfile": ele
                            }
                            await updateDoc(documentRef, data)
                        } catch (e) {
                            console.log("firebase log out :e:", e.toString());
                        }
                    }
                }
            }
        })
        const documentRef = doc(db, 'upload', ele.id);
        const data = ele
        await updateDoc(documentRef, data)
    return arr;
}

StoreService.deleteImage = async (ele) => {
    var arr = true;
    console.log("running the delete reports API")
    if (ele.shareStory) {
        const documentRef = doc(db, 'users', ele.uid);
        const data = { 'stories': FieldValue.arrayRemove([ele.id]) }
        await updateDoc(documentRef, data)
    }
    if (ele.isProfile) {
        const docRef = doc(db, "users", ele.uid);
        const docSnap = await getDoc(docRef);
        arr = docSnap.data()
        if (arr.finalProfile.id == ele.id) {
            const documentRef = doc(db, 'users', ele.uid);
            const data = {
                "finalProfile": {},
                "shareProfile": false
            }
            await updateDoc(documentRef, data)
        }
    }
    ele.savedList.forEach(async (elm) => {
        if (elm.includes('message')) {
            var midList = (elm.split(":")[1]).split("-")
            var mid = midList[0] + "-" + midList[1]
            var sid = midList[2]
            console.log("midList==00:", midList, mid, sid);
            const documentRef = doc(db, 'messages', mid, mid, sid);
            await deleteDoc(documentRef)
        }
    })
    if (ele.type == 1) {
        // const fileRef = storage.refFromURL(ele.imageUrl);
        // const fileRef = ref(storage,ele.imageUrl);
        console.log("==--:ele:",ele)
        console.log("==--:ele00:",ele.imageUrl)
        const fileRef = ref(storage, ele.imageUrl);
        await deleteObject(fileRef)
            .then(() => {
                console.log('Image deleted successfully');
            })
            .catch((error) => {
                console.error('Error deleting image: ', error);
                return false;
            })
    } else if (ele.type == 6) {

        var response = await axios.post('https://ws.api.video/auth/api-key',
            { "apiKey": VideoConfig.apiKey });

        console.log("respnse==9900:", response)

        var removeToken = response.data.access_token;

        var videoUrlParams = ele.videoUrl.split("/");

        try {
            var response = await axios.delete(
                'https://ws.api.video/videos/' + videoUrlParams[4],
                {
                    headers: {
                        'Authorization':
                            'Bearer ' + removeToken
                    }
                },
            );
            console.log(["removed result from api.video:", response.statusCode]);
        } catch (e) {
            console.log(["video remove error:-----:", e.toString()]);
        }

    }
    const documentRef = doc(db, 'upload', ele.id);
    await deleteDoc(documentRef)
         .then(() => {
            console.log("video removed successfully:-----:");
        })
        .catch((error) => {
            console.error("Error deleting document: ", error);
            return false;
        });
    return arr;
}

StoreService.deleteFeedback = async (ele) => {
    var arr = true;
    console.log("running the delete reports API")
    const documentRef = doc(db, 'feedback', ele.feedId);
    await deleteDoc(documentRef)
         .then(() => {
            console.log("Feedback removed successfully")
        })
        .catch((error) => {
            console.error("Error deleting document: ", error);
            return false;
        });
    return arr;
}

StoreService.getVideos = async (filter) => {
    var arr = [];
    console.log("running the get video reports API")
    const dmsRef = collection(db, "upload");
    const docRef = query(dmsRef); //for example id = "71dc954d-d2a4-4892-8257-98696fe776cd"

    const docSnap = await getDocs(docRef);

    docSnap.forEach(async (document) => {
        var eachone = document.data();
        eachone['reportCount'] = 0;
        arr.push(eachone)
        const documentRef = doc(db, 'upload', eachone.id);
        await updateDoc(documentRef, eachone)
    });

    console.log("storearray:", arr.length)

    return arr;
}

export default StoreService