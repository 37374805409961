import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Flirty';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
// export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/user/user-list`;
export const UNAUTHENTICATED_ENTRY = '/login'

export const VideoConfig = {
	uploadToken: 'to6pp2EgVBM1sqpKuT6Jja4Q',
	apiKey: 'NSPsvt1qW1DP0BP9x4aNkizpwofxnkxE6Tzt2wu3Nng'
}

export const FirestoreConstants = {
	pathUserCollection:"users",
	pathMessageCollection:"messages",
	shareCollection:"share",
	filesCollection:"files",
	nickname:"nickname",
	aboutMe:"aboutMe",
	photoUrl:"photoUrl",
	id:"id",
	chattingWith:"chattingWith",
	idFrom:"idFrom",
	idTo:"idTo",
	timestamp:"timestamp",
	content:"content",
	type:"type",
	userCollection:"users",
	uploadCollection:"upload",
	flirtyCollection:"flirty",
	displayName:"displayName",
	status:"status",
	block:"block",
	shareId:"shareId",
	videoUrl:"videoUrl",
	caption:"caption",
	engineResult:"engineResult",
}

export const TypeMessage = {
	text: 0,
	image: 1,
	sticker: 2,
	audio: 3,
	extend: 4,
	match: 5,
	video: 6,
	bySuper: 7
}  

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
